import React from 'react'

import Layout from '../components/layout'

// import ViewGallery from '../components/gallery/gallery'

import { MailForm } from '../components/form/form';

const POC = () => (
  <Layout>
    {/* <ViewGallery></ViewGallery> */}
    <MailForm></MailForm>
  </Layout>
)

export default POC
